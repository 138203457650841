import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui imports
// mui wrappers
import RbgTableContainer from 'ui_component/mui-wrappers/TableContainer/RbgTableContainer';
import RbgTable from 'ui_component/mui-wrappers/Table/RbgTable';
import RbgTableBody from 'ui_component/mui-wrappers/TableBody/RbgTableBody';
import RbgTableRow from 'ui_component/mui-wrappers/TableRow/RbgTableRow';
import RbgTableCell from 'ui_component/mui-wrappers/TableCell/RbgTableCell';
// third party imports
import dayjs from 'dayjs';
import { IconExclamationCircle, IconInfoCircle, IconRotateClockwise, IconUser } from '@tabler/icons-react';
// project imports
import { iconSize } from 'store/constant';
import MainCard from 'ui_component/cards/MainCard';
import RbgTextInput from 'ui_component/extended/Form/RbgTextInput';
import { useQuery } from '@tanstack/react-query';
import RbgLoadingRow from '../../../../ui_component/mui-wrappers/TableRow/RbgLoadingRow';
import './staffList.scss';
import RbgLink from '../../../../ui_component/mui-wrappers/Link/RbgLink';
import RbgTooltip from '../../../../ui_component/tooltip/RbgTooltip';
import RbgStack from '../../../../ui_component/mui-wrappers/Stack/RbgStack';
import RbgTypography from '../../../../ui_component/mui-wrappers/Typography/RbgTypography';
import RbgButton from '../../../../ui_component/mui-wrappers/Button/RbgButton';
import { snackbarSuccess } from '../../../../store/reducers/snackbarReducer';
import { AlternateEmail, Email, PhoneIphone } from '@mui/icons-material';
import RbgIcon from '../../../../ui_component/mui-wrappers/Icon/RbgIcon';
import NewStarterList from '../NewStarters/NewStarterList';
import ErrorCard from '../../../../ui_component/error/ErrorCard';
import RbgTableHeadFilter from '../../../../ui_component/mui-wrappers/TableHead/RbgTableHeadFilter';
import getStaffListComparator from './staffListComparator';
import { Avatar } from '@mui/material';

function StaffList() {
    const dispatch = useDispatch();
    const { bar } = useSelector((state) => state.bar);
    const [search, setSearch] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const hasLocation = !!bar.cost_centre;
    const {
        data,
        isFetching: loading,
        error,
        refetch
    } = useQuery({
        queryKey: [{ url: 'staff/list', params: { cost_centre: bar.cost_centre, live_only: true } }],
        enabled: hasLocation
    });

    const handleSort = (event, property) => {
        const isAsc = orderBy === property && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const comparator = getStaffListComparator(orderBy);
    const staffList = data.data.slice().sort((a, b) => (sortDirection === 'asc' ? comparator(a, b) : -comparator(a, b)));
    const filterStaffList = staffList.filter((staff) => {
        if (search) {
            return (
                staff.display_name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                staff.payroll_ref.toLowerCase().indexOf(search.toLowerCase()) > -1
            );
        }
        return true;
    });

    const headCells = [
        {
            id: 'reset',
            label:
                orderBy !== '' ? (
                    <RbgTooltip title="Reset Table">
                        <RbgButton
                            icon={<IconRotateClockwise />}
                            onClick={async () => {
                                setSortDirection('asc');
                                setOrderBy('');
                                await refetch();
                            }}
                        />
                    </RbgTooltip>
                ) : (
                    <span style={{ display: 'block', height: '35px' }} />
                ),
            sort: false
        },
        {
            id: 'display_name',
            label: 'Name',
            sort: true
        },
        {
            id: 'payroll_ref',
            label: 'Ref',
            sort: true
        },
        {
            id: 'dept',
            label: 'Dept',
            sort: true,
            hideCell: bar.cost_centre !== '0001'
        },
        {
            id: 'role_name',
            label: 'Role',
            sort: true
        },
        {
            id: 'contact',
            label: 'Contact',
            sort: false
        },
        {
            id: 'start_date',
            label: 'Start Date',
            sort: true
        },
        {
            id: 'contracted_hours',
            label: 'Contract Hours',
            sort: true
        }
    ];
    if (!hasLocation) {
        return (
            <MainCard title="Team Member List" icon={<IconUser size={iconSize} />}>
                <ErrorCard>No location selected</ErrorCard>
            </MainCard>
        );
    }
    if (error) {
        return (
            <MainCard title="Team Member List" icon={<IconUser size={iconSize} />}>
                <ErrorCard>{error}</ErrorCard>
            </MainCard>
        );
    }

    const newStarters = data.data.filter((item) => {
        return item.initial_training_complete === false;
    });
    return (
        <MainCard
            title="Team Member List"
            secondary={
                <RbgTextInput
                    label="Search By Name"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && filterStaffList.length === 1) {
                            window.location.href = `/staff/view/${filterStaffList[0].id}`;
                        }
                    }}
                />
            }
            icon={<IconUser size={iconSize} />}
        >
            <NewStarterList search={search} initialTraining={newStarters} />
            <RbgTableContainer>
                <RbgTable>
                    <RbgTableHeadFilter headCells={headCells} handleSort={handleSort} orderBy={orderBy} order={sortDirection} />
                    {loading ? (
                        <RbgTableBody>
                            <RbgLoadingRow colSpan={7} />
                        </RbgTableBody>
                    ) : (
                        <RbgTableBody>
                            {filterStaffList.map((staff) => {
                                let status = '';
                                if (staff.suspended) {
                                    status = 'suspended';
                                }
                                if (staff.initial_training_complete === false) {
                                    status = 'training-incomplete';
                                }
                                if (staff.leave_date !== null) {
                                    status = 'future-leaver';
                                }
                                const errorActions = staff.actions.data.filter((action) => action.severity_id === 3);
                                const warningActions = staff.actions.data.filter((action) => action.severity_id === 2);
                                const infoActions = staff.actions.data.filter((action) => action.severity_id === 1);
                                const icons = staff.actions.data.filter((action) => action.severity_id === 4);
                                return (
                                    <RbgTableRow key={staff.id} className={`status-${status}`}>
                                        <RbgTableCell>
                                            <RbgStack direction="row" alignItems="center">
                                                {errorActions.length > 0 && (
                                                    <RbgTooltip
                                                        title={errorActions.map((action) => action.description_rendered).join(', ')}
                                                    >
                                                        <IconExclamationCircle color="red" size={15} />
                                                    </RbgTooltip>
                                                )}
                                                {warningActions.length > 0 && (
                                                    <RbgTooltip
                                                        title={warningActions.map((action) => action.description_rendered).join(', ')}
                                                    >
                                                        <IconExclamationCircle color="orange" size={15} />
                                                    </RbgTooltip>
                                                )}
                                                {infoActions.length > 0 && (
                                                    <RbgTooltip title={infoActions.map((action) => action.description_rendered).join(', ')}>
                                                        <IconInfoCircle color="blue" size={15} />
                                                    </RbgTooltip>
                                                )}
                                            </RbgStack>
                                        </RbgTableCell>
                                        <RbgTableCell>
                                            <RbgLink router to={`/staff/view/${staff.id}`}>
                                                {staff.selfie_image ? (
                                                    <RbgTooltip title={<Avatar alt={staff.display_name} src={staff.selfie_image} />}>
                                                        {staff.display_name}
                                                    </RbgTooltip>
                                                ) : (
                                                    staff.display_name
                                                )}
                                            </RbgLink>
                                            <RbgStack direction="row" spacing={1} useflexgap="true" justifyContent="center">
                                                {icons.map((icon) => {
                                                    return (
                                                        <RbgTooltip
                                                            key={icon.id}
                                                            title={icon.description_rendered}
                                                            sx={{ display: 'inline' }}
                                                            placement="bottom"
                                                        >
                                                            <RbgIcon name={icon.icon} />
                                                        </RbgTooltip>
                                                    );
                                                })}
                                            </RbgStack>
                                        </RbgTableCell>
                                        <RbgTableCell>{staff.payroll_ref}</RbgTableCell>
                                        {bar.cost_centre === '0001' && <RbgTableCell>{staff.support_centre_department}</RbgTableCell>}
                                        <RbgTableCell>
                                            {staff.support_centre_role ? staff.support_centre_role : staff.role_name}
                                        </RbgTableCell>
                                        <RbgTableCell>
                                            <RbgStack direction="row" useflexgap="true" justifyContent="center" alignItems="center">
                                                {staff.email_address && (
                                                    <RbgTooltip title={`Personal Email: ${staff.email_address}`}>
                                                        <RbgButton
                                                            sx={{ border: 0 }}
                                                            onClick={() => {
                                                                dispatch(snackbarSuccess('Personal Email Copied'));
                                                                window.navigator.clipboard.writeText(staff.email_address);
                                                            }}
                                                            icon={<AlternateEmail sx={{ maxWidth: '20px' }} />}
                                                        />
                                                    </RbgTooltip>
                                                )}
                                                {staff.work_email_address && (
                                                    <RbgTooltip title={`Work Email: ${staff.work_email_address}`}>
                                                        <RbgButton
                                                            sx={{ border: 0 }}
                                                            onClick={() => {
                                                                dispatch(snackbarSuccess('Work Email Copied'));
                                                                window.navigator.clipboard.writeText(staff.work_email_address);
                                                            }}
                                                            icon={<Email sx={{ maxWidth: '20px' }} />}
                                                        />
                                                    </RbgTooltip>
                                                )}
                                                {staff.mobile && (
                                                    <RbgTooltip title={staff.mobile}>
                                                        <RbgButton
                                                            sx={{ border: 0 }}
                                                            onClick={() => {
                                                                dispatch(snackbarSuccess('Mobile copied'));
                                                                window.navigator.clipboard.writeText(staff.mobile);
                                                            }}
                                                            icon={<PhoneIphone sx={{ maxWidth: '20px' }} />}
                                                        />
                                                    </RbgTooltip>
                                                )}
                                            </RbgStack>
                                        </RbgTableCell>
                                        <RbgTableCell>
                                            <RbgStack direction="column" spacing={0}>
                                                <RbgTypography>{dayjs(staff.start_date).format('DD/MM/YYYY')}</RbgTypography>
                                                {staff.leave_date !== null && (
                                                    <RbgTypography sx={{ color: 'red' }}>
                                                        {dayjs(staff.leave_date).format('DD/MM/YYYY')}
                                                    </RbgTypography>
                                                )}
                                            </RbgStack>
                                        </RbgTableCell>
                                        <RbgTableCell>{staff.contracted_hours}</RbgTableCell>
                                    </RbgTableRow>
                                );
                            })}
                        </RbgTableBody>
                    )}
                </RbgTable>
            </RbgTableContainer>
        </MainCard>
    );
}

export default StaffList;
